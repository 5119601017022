import React from 'react';
import './HomeLand.css';

const HomeLand = () => {
    return (
        <div className="notfound-b">
            <img className="img-fluid" src="https://i.ibb.co/cDXnwd0/doublespace.jpg" alt="bg" />
        </div>
    );
};

export default HomeLand;